@import 'color';
@import 'reponsive';

.mcf-mes {
  padding: 10px 4px;
}

.mcf-btn {
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  button {
    outline: none;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    user-select: none;
    touch-action: manipulation;
    font-size: 14px;
    height: 32px;
    padding: 4px 15px;
    border-radius: 6px;
  }

  .confirm {
    background: $r-blue;
    color: $color-white;
    .zoom-button {
      color: $color-white;
    }
    &:hover {
      background-color: $l-blue;
    }
  }

  .danger {
    background: $inspire-red;
    color: $color-white;
    .zoom-button {
      color: $color-white;
    }

    &:hover {
      background-color: #ff7875;
    }
  }

  .cancel {
    border: 1px solid $sp-gray-2;

    &:hover {
      background-color: $sp-gray-1;
    }
  }
}

.app-breadcrumb {
  display: flex;
  align-items: center;
  color: $sp-gray-2;

  .a-link {
    color: $sp-gray-2;
  }

  .a-link:hover {
    color: $r-blue !important;
  }

  .icon {
    font-size: 12px;
    padding: 4px 6px;
    display: flex;
    align-items: flex-end;
  }
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 320px;
  display: block;
}

.text-duplicate {
  max-width: 450px;
  display: flex;
  align-items: center;
}

.text-ellipsis-customer {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.tooltip-customer {
  color: black;
}

.spinning-center {
  .ant-spin-spinning {
    max-height: 100% !important;
  }
}

.spinning-route {
  .ant-spin-spinning {
    max-height: 100vh !important;
    height: 100vh !important;
    width: 100vw !important;
  }
}

.input-search::-webkit-input-placeholder {
  color: $sp-gray-2;
}
