@import 'normalize.css';
:root {
  --laptop-width: 1200px;
  --pc-width: 1400px;
  --primary: #fe2c55;
  --black: #000;
  --white: #fff;
  --text-color: #161823;
  --color-error: rgb(255, 0, 38);
  --font-family: 'Arial', sans-serif;
  --color-border: #d9d9d9; // Default layout
  --default-layout-header-height: 6.361vh;
  --default-layout-footer-height: 3.578vh;
  --default-layout-aside-height: 6.5vw;
  --default-layout-width: 100%;
  --default-layout-horizontal-spacer: 15px;

  --default-layout-background-aside: rgb(115, 115, 115);
  --default-layout-background-gray: rgb(45, 45, 45);
  --default-layout-background-white: rgb(255, 255, 255);
  --default-layout-background-supporting-gray1: rgb(217, 217, 217);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: var(--font-family);
  font-size: 1.6rem;
  line-height: 1.5;
  text-rendering: optimizespeed;
  color: var(--text-color);
  overflow-y: overlay;
}

// Scrollbar CSS
html *::-webkit-scrollbar {
  border-radius: 0;
  width: 8px;
}

html *::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.15);
}

html *::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
}

button,
input,
[tabindex] {
  outline: none;
  border: none;
}

a {
  color: var(--text-color);
  text-decoration: none;
}

// Custom tippy tooltip
body {
  .tippy-box {
    border-radius: 8px;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.2rem;
    background-color: rgba(84, 84, 84, 0.92);
  }

  .tippy-arrow {
    color: rgba(84, 84, 84, 0.92);
  }

  .tippy-content {
    padding: 8px 9px;
  }

  .tippy-box[data-placement^='bottom'] > .tippy-arrow:before {
    top: -8px;
  }
  .ant-form-item-explain-error {
    text-align: left;
  }
  .ant-form-item-required::before {
    content: none !important;
    visibility: hidden;
  }
  .ant-form-item-required::after {
    display: inline-block;
    margin-inline-end: 4px;
    color: var(--default-layout-background-gray);
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*' !important;
    visibility: initial !important;
    top: -3px;
  }
  .form-item-information {
    .ant-row {
      .ant-col {
        .ant-form-item-required {
          position: relative;
          img {
            top: 0px;
          }
          .label-required {
            position: relative;
            margin-right: 10px;
            &:after {
              position: absolute;
              display: inline-block;
              margin-inline-end: 4px;
              color: var(--default-layout-background-gray);
              font-size: 14px;
              font-family: SimSun, sans-serif;
              line-height: 1;
              content: '*' !important;
              visibility: initial !important;
              top: -1px;
            }
          }
          &:after {
            content: '' !important;
          }
        }
      }
    }
  }

  .pages__createProject {
    .content__action {
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
  .component__attachment {
    .ant-upload-text {
      margin-bottom: 64px !important;
    }
  }
  .modal__confirm {
    text-align: center;
    .title__container {
      display: flex;
      align-items: center;
      .icon {
        flex: left;
      }
      .title_header {
        flex: start;
        font-weight: bold;
        margin-left: 5px;
        font-size: larger;
      }
      .title {
        flex: auto;
        font-weight: bold;
      }
    }
    .description {
      margin-top: 10px;
      margin-bottom: 15px;
    }
  }
  .ant-table-header {
    border-radius: 0 !important;
  }
}
.suffix-label {
  color: #81817f;
}

.list-item__text {
  text-align: center;
}

.icon-hover {
  cursor: pointer;
}

.list-text {
  padding-left: 10px;
}
.list-item__status,
.list-item__title {
  text-align: center;
}
.list-item__title_left {
  text-align: left;
}
// Custom CSS Site Usage
.site-usage__form {
  .form-item-custom {
    margin-bottom: 0 !important;
  }
  .site-usage__label--after-none {
    margin-bottom: 0 !important;
    .ant-form-item-label > label::after {
      content: none !important;
    }

    // .component__attachment--listFiles-content .listFiles__item--title {
    //   width: 300px !important;
    // }

    .ant-form-item-control-input-content {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  .color-required {
    .ant-form-item-label > label {
      color: var(--primary);
    }
  }
}

.tab-action {
  float: right;
  display: flex;
  gap: 8px;

  .icon {
    width: 24px;
    height: 24px;
    cursor: pointer;

    img {
      width: 100%;
    }
  }
}
.ant-divider-inner-text {
  span {
    color: var(--text-color) !important;
  }
}
.ant-picker-cell-selected {
  .ant-picker-cell-inner {
    background-color: #1677ff !important;
  }
}

.ant-table-thead .ant-table-row-expand-icon-cell {
  background-color: var(--default-layout-background-supporting-gray1) !important;
}

.spinning-center-modal-import {
  position: fixed !important;
  inset: 0;
  z-index: 1010 !important;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.45);
  pointer-events: none;

  .ant-spin-dot .ant-spin-dot-spin {
    margin: 0 auto;
    top: 50% !important;
    bottom: 50% !important;
  }

  :where(.css-dev-only-do-not-override-6139ys).ant-spin .ant-spin-dot {
    top: 50% !important;
    bottom: 50% !important;
  }

  .ant-spin .ant-spin-dot {
    top: 50% !important;
    bottom: 50% !important;
  }
  span {
    top: 50% !important;
    bottom: 50% !important;
  }
}
.app-table-modal-import {
  th,
  td {
    text-align: center !important;
  }
}

.attachment-module-custom-width {
  width: 66.6%;
}

.bases__padding--form {
  padding: 0 3.333vw;
}

@media only screen and (max-width: 1400px) {
  .pages__subProjectDetail {
    padding: 20px 60px 10px 140px !important;

    &--content {
      height: auto !important;
      margin-bottom: 32px !important;
    }
  }

  .project-page {
    .table-data {
      .table-group {
        min-height: calc(52vh) !important;
      }
    }
  }
}

.icon-custom {
  width: 24px;
  height: 24px;
}

.icon-save {
  width: 24px;
  height: 24px;
}

.icon-home {
  width: 80px;
  height: 80px;
}
@media only screen and (max-width: 1700px) and (min-width: 1400px) {
  .tab-action {
    display: flex;
    gap: 8px;

    .icon {
      width: 24px;
      height: 24px;
      cursor: pointer;

      img {
        width: 20px;
      }
    }
  }
}
.tooltip-style {
  color: rgba(0, 0, 0, 0.88);
  text-align: center;
  width: 250px;
}
.tooltip-img {
  width: 20px;
}
.form-item-losses-rate {
  height: 50px !important;
}
.icon-tooltip {
  position: absolute;
  right: -10px;
}

.font-semibold {
  font-weight: 600 !important;
}

.font-semibold-country-of-operation {
  display: none;
}

.custom-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px;
}

.custom-empty .ant-empty-image img {
  width: 55px;
  height: auto;
  font-size: 12px;
}

.custom-empty .ant-empty-description .ant-typography {
  font-size: 14px;
  color: rgba(87, 87, 87, 0.35) !important;
}

.is-location-link-disable {
  display: none !important;
}

.type-project {
  text-align: left;

  .text-type-project {
    font-weight: 700;
    color: rgb(255, 0, 38);
  }
}
// Set width for button confirm
.w-btn--confirm {
  width: 80px;
}

.description-modal-confirm-change-location .description {
  text-align: left !important;
}

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

/** Custom CSS for Ant Design Checkbox */
/** Overwrite background color of checkbox */

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: rgb(255, 0, 38);
  border-color: rgb(255, 0, 38);
}

.ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  background-color: #ff7875 !important;
}

.ant-checkbox-checked:hover .ant-checkbox-inner {
  background-color: #ff7875 !important;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: #ff7875 !important;
}

/** End Custom CSS for Ant Design Checkbox */
