$color-white: #fff;
$color-white-gray: #dfdfdf;
$color-white-lighten: #f7f7f7;
$color-white-opacity: rgba(255, 255, 255, 0);

$color-dark-black: #000000;
$color-black: #222222;
$color-black-opacity: rgba(0, 0, 0, 0.5);
$color-white-opacity: rgba(0, 0, 0, 0.1);

$color-blue: #0d6efd;
$color-blue-opacity: rgba(0, 122, 225, 0.05);
$color-navy-blue: #000080;

$color-red: #e60012;
$color-invaild: #cc0000;
$color-red-opacity: rgba(230, 0, 18, 0.1);

$color-orange: #945f29;

$color-purple: #80037e;

$color-green: #198754;

$color-gray: #f4f5f5;

$color-yellow: #ffc93f;

$color-cyan: #3c8dbc;

$color-pizazz: #ff8900;

$color-dove-gray: #717171;

$color-japonica: #dc7279;

$color-danube: #5995cb;

$color-purple-heart: #4e2bdb;

$color-pink-light: rgb(206, 187, 187);

$color-light-blue: #a9d5de;

$color-blue-sky: #276f86;

$color-blue-white: #d2fcfc;

$color-blue-cloud: #1678c2;

$color-border-gray: #6c6b6b;

$color-modal-background: #fbfcfc;

$color-modal-text: #414141;

$color-border-line: #a6a6a6;

$background-input-tracking: #e8e8e8;
$background-btn-delete: #cc0000;
$btn-unsort: #ccced0;

$color-smoke: #cacbcd;
$color-blue-text: #2064b4;
$color-sidebar: #f0f0f0;
$color-red-filter: invert(20%) sepia(61%) saturate(7360%) hue-rotate(347deg) brightness(84%) contrast(118%);
$color-white-filter: invert(100%) sepia(3%) saturate(181%) hue-rotate(354deg) brightness(118%) contrast(100%);
$color-black-filter: invert(0%) sepia(85%) saturate(8%) hue-rotate(30deg) brightness(99%) contrast(100%);
$color-gray-filter: invert(81%) sepia(0%) saturate(32%) hue-rotate(175deg) brightness(95%) contrast(101%);
$color-light-green-filter: invert(56%) sepia(89%) saturate(656%) hue-rotate(79deg) brightness(96%) contrast(83%);

@function color-shadow($opacity) {
  @return rgba(34, 34, 34, $opacity);
}

$sp-red: rgb(126, 0, 10);
$sp-gray-1: rgb(217, 217, 217);
$sp-gray-2: rgb(115, 115, 115);
$inspire-red: rgb(255, 0, 38);
$inspire-white: rgb(255, 255, 255);
$inspire-gray: rgb(45, 45, 45);
$r-blue: rgb(9, 63, 180);
$l-blue: rgb(128, 196, 255);
$menu-hover: #1775e01a;
